import http, { api } from './_http'

const headers = { headers: { 'cache-control': 'no-cache' } }

const Label = {
  get: (entityId, fieldName) => http.get(`/msvc/v1/translations/Label/${entityId}/${fieldName}`, null, headers),
  set: (entityType, entityId, fieldName, locale, value) => http
    .put(`/msvc/v1/translations/${entityType}/${entityId}/${fieldName}/${locale}`, value),
  getAllLabels: () => http.get('/msvc/v1/translations/Label?entityType=Label', null, headers),
  getLabelsDefinition: () => api.get('/msvc/v1/translations/labels/definition'),
  getLabelBulkTranslations: bulkTranslationsRequest => http
    .post('/msvc/v1/translations/values/bulk', bulkTranslationsRequest)
}

export default Label
