import UniversalCookie from 'universal-cookie'
import Router from 'next/router'
import { User as UserService } from '@services'
import { logger } from '.'

const CONFIG = require('../../app/config')

const Cookies = new UniversalCookie()

export const setCookie = (key, value, options) => {
  Cookies.set(key, value, options)
}

export const getCookie = (req = null, key, fallback = null) => {
  let result = fallback

  try {
    if (typeof window !== 'undefined') {
      result = Cookies.get(key) || fallback
    } else {
      result = (req && req.universalCookies)
        ? req.universalCookies.get(key)
        : fallback
    }
  } catch (e) {
    logger(`!!! CRITICAL: ${e}`)
  }

  return result
}

export const setUserTokens = (response) => {
  const serverSessionObject = { ...response.data }
  let sessionExpire = null
  let refreshTokenSessionExpire = null

  // Current time + expire time from API, in ms. Converted to date object.
  sessionExpire = new Date(new Date().getTime() + (serverSessionObject.sessionSummary.expireInSeconds * 1000))
  refreshTokenSessionExpire = new Date(
    new Date().getTime() + (serverSessionObject.sessionSummary.expireRefreshInSeconds * 1000)
  )

  setCookie(CONFIG.COOKIE_KEYS.CSRF_TOKEN, serverSessionObject.sessionSummary.csrfToken, {
    expires: sessionExpire, path: '/'
  })
  setCookie(CONFIG.COOKIE_KEYS.ACCESS_TOKEN, serverSessionObject.accessToken, { expires: sessionExpire, path: '/' })
  setCookie(CONFIG.COOKIE_KEYS.REFRESH_TOKEN, serverSessionObject.sessionSummary.refreshToken, {
    // httpOnly: true not working on our test env so marked this as a false for testing purpose
    expires: refreshTokenSessionExpire, path: '/', secure: true, httpOnly: false, sameSite: 'lax'
  })

  // need a JSESSIONID cookie or it will make some bugs on legacy. the value of the JSESSIONID doesn't matter
  setCookie(CONFIG.COOKIE_KEYS.JSESSIONID, 'dummy', { expires: sessionExpire, path: '/' })
}

export const getAccessToken = (req = null) => getCookie(req, CONFIG.COOKIE_KEYS.ACCESS_TOKEN)

export const getRefreshToken = (req = null) => getCookie(req, CONFIG.COOKIE_KEYS.REFRESH_TOKEN)

export const clearUserTokens = (req = null) => {
  if (req && req.universalCookies) {
    req.universalCookies.remove(CONFIG.COOKIE_KEYS.CSRF_TOKEN)
    req.universalCookies.remove(CONFIG.COOKIE_KEYS.ACCESS_TOKEN)
    req.universalCookies.remove('fullName')
    req.universalCookies.remove(CONFIG.COOKIE_KEYS.JSESSIONID)
    req.universalCookies.remove(CONFIG.COOKIE_KEYS.REFRESH_TOKEN)
  } else {
    Cookies.remove(CONFIG.COOKIE_KEYS.CSRF_TOKEN, { path: '/' })
    Cookies.remove(CONFIG.COOKIE_KEYS.ACCESS_TOKEN, { path: '/' })
    Cookies.remove('fullName', { path: '/' })
    Cookies.remove(CONFIG.COOKIE_KEYS.JSESSIONID, { path: '/' })
    Cookies.remove(CONFIG.COOKIE_KEYS.REFRESH_TOKEN, { path : '/' })
  }
}

export const logoutTabs = () => {
  window.addEventListener('storage', (event) => {
    if (event.key === 'logout-event') {
      localStorage.removeItem('logout-event')
      Router.push('/signin')
    }
  })
}

const getAccessTokenByRefreshTokenAPI = async (reFreshToken) => {
  let token = null
  try {
    const result: any = await UserService.reFreshToken(reFreshToken)
    if (result.status === 200) {
      setUserTokens(result)
      token = result.data.accessToken
    }
  } catch (error) {
    logger(error)
  }
  return token
}

export const sessionAccessToken = () => {
  const userAccessToken = getAccessToken()
  const reFreshToken = getRefreshToken()
  let token = null
  if (userAccessToken) {
    token = userAccessToken
  } else if (reFreshToken) {
    token = getAccessTokenByRefreshTokenAPI(reFreshToken)
  }
  return token
}

export default {}
