import qs from 'query-string'
import http from './_http'

const Gallery = {
  getGalleries: (params) => {
    const paramsString = qs.stringify(params)
    return http.get(`/msvc/v1/artcatalog/gallery/items?${paramsString}`)
  },

  getGalleryById: id => http.get(`/msvc/v1/artcatalog/gallery/item/${id}`),

  getGalleryByAccountId: accountId => http.get(`/msvc/v1/artcatalog/gallery/account/${accountId}`),

  getAccountNameByAccountId: id => http.get(`/msvc/v1/accounts/items/${id}`)
}

export default Gallery
