const theme = {

  // font family
  bodyFont: 'Arial, sans-serif',
  headingFont: 'Helvetica',

  // font sizes
  bodySize: '15px',
  descriptionSize: '16px',
  languagesSize: '14px',
  buttonFontSize: '15px',
  inputFontSize: '15px',
  labelFontSize: '14px',
  validationErrorFontSize: '12px',

  // line heights
  bodyLineHeight: '24px',

  // colors
  colorBlack: '#000',
  colorBody: '#444749',
  colorBorder: '#d0d0d0',
  colorBox: '#dfdfdf',
  colorContent: '#f7f7f7',
  colorHeading: '#1E2327',
  colorDark: '#1d2327',
  colorDisabled: '#e5e5e5',
  colorFormBorder: '#bdbdbd',
  colorGreen: '#37d142',
  colorLoginBorder: '#dedede',
  colorPlaceholder: '#bdbdbd',
  colorSeparator: '#bdbdbd',
  colorTableBkg: '#f5f5f5',
  colorTableBorder: '#eceeef',
  colorTableText: '#373a3c',
  colorWhite: '#fff',
  colorCaption: '#bdbdbd',
  colorProgresBar: '#bdbdbd',
  colorBkgError: '#fff5f7',
  colorValidationError: '#ff3860',
  inputBorderColor: '#dedede',
  inputColor: '#1d2327',

  // Containers
  desktopContainer: '1345px',

  // Breakpoints
  largeMonitorBreakpoint: '1200px'
}

export default theme
