export function formatDate(startDate, endDate = null) {
  let start = startDate
  let end = endDate
  const formatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
  const replaceDash = d => d.replace(/-/g, '/').split(' ')[0].toString()
  // @ts-ignore
  const createDate = date => new Date(replaceDash(date)).toLocaleDateString('en-GB', formatOptions)

  if (start && start.includes('-')) {
    start = createDate(start)
  }
  if (end && end.includes('-')) {
    end = createDate(end)
  }

  return !end ? start : `${start} - ${end}`
}

export default {}
