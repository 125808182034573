import { useContext } from 'react'
import NoSSR from 'react-no-ssr'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Link from 'next/link'

import useHeader from '@hooks/useHeader'
import { Container, ArtBaselLogo, ButtonIcon, Row, Col } from '@mch-group/uikit-components'
import { whiteListMobileSidebar } from '@constants'
import { UserContext } from '@context/User'
import { PageContext } from '@context/Page'

const Header = () => {
  const router = useRouter()
  const context = useContext(UserContext)
  const { logOut, fullName } = context
  const {
    state: { isSidebarOpen }
  } = PageContext()
  const { menuRef, isVisible, setVisible, onClickMenu } = useHeader()

  return (
    <div className='sticky-top navbar-parent-container m-0 p-0'>
      <MainContainer className='navbar-container' data-testid='mch-navbar'>
        {/** @ts-ignore */}
        <Row gutter='g-0' className='px-5 px-xl-8'>
          <Col className='d-xl-none col-auto pe-5 pe-sm-4 py-7 py-xs-sm-4'>
            {whiteListMobileSidebar.includes(router?.pathname) && (
              <>
                {isSidebarOpen ? (
                  <ButtonIcon icon='close' onClick={onClickMenu} className='profile-btn' />
                ) : (
                  <ButtonIcon icon='menu' onClick={onClickMenu} className='profile-btn' />
                )}
              </>
            )}
          </Col>
          <Col className='pe-5 py-7 py-xs-sm-4'>
            <Link href='/menu' legacyBehavior>
              <a>
                <ArtBaselLogo variant='default' color='dark' width={undefined} height={undefined} />
              </a>
            </Link>
          </Col>
          <NoSSR>
            <Col className='py-7 py-xs-sm-4 item-align-end'>
              <div className='profileflyout-container container-align'>
                <div className={`underline-text ${isVisible ? 'd-block' : 'd-none'}`} />
                <ButtonIcon icon='guest' onClick={() => setVisible(!isVisible)} className='profile-btn' />

                {isVisible && (
                  <ul className='profileflyout-menu' ref={menuRef}>
                    <li className='item-menu-label mx-5 pt-7 pb-2'>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <p className='text-label-large mb-0'>Welcome,</p>
                          <p className='text-label-large mb-0'>{fullName}</p>
                        </div>
                      </div>
                    </li>
                    <li className='item-menu-label logout-label py-5 mx-5' onClick={logOut} key='Logout'>
                      Logout
                    </li>
                  </ul>
                )}
              </div>
            </Col>
            <div className={`header-overlay ${isVisible ? 'visible' : ''}`} />
          </NoSSR>
        </Row>
      </MainContainer>
      <MenuUnderLine />
    </div>
  )
}

const MenuUnderLine = styled.div`
  background-color: var(--bs-mch-white) !important;
  border-top: 2px solid var(--bs-mch-gray-200) !important;
`

const MainContainer = styled(Container)`
  .navbar-parent-container {
    background-color: var(--bs-mch-white);
    width: 100%;
  }

  @media (min-width: 0) and (max-width: 767px) {
    height: 56px;

    .py-xs-sm-4 {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }

    svg {
      width: 72px;
      height: 24px;
    }

    .btn-icon.m {
      width: 32px;
      height: 32px;
      padding: 8px;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    height: 88px;
  }

  @media (min-width: 1280px) and (max-width: 1536px) {
    height: 102px;
    padding-top: 5px;
  }

  @media (min-width: 1537px) {
    height: 104px;
    padding-top: 6px;
  }

  .header-overlay {
    position: fixed;
    z-index: 997;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--bs-mch-black);
    opacity: 0;
    transition: opacity 300ms ease-out, visibility 300ms ease-out;
    visibility: hidden;
    top: 104px;

    @media (min-width: 0) and (max-width: 767px) {
      top: 56px;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      top: 88px;
    }

    &.visible {
      opacity: 0.3;
      transition: opacity 300ms ease-out, visibility 300ms ease-out;
    }
  }

  .item-align-end {
    display: flex;
    justify-content: end;
  }

  .container-align {
    display: flex;
    justify-content: end;
    align-items: center;

    @media (min-width: 0) and (max-width: 767px) {
      margin-right: 12px;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      margin-right: 16px;
    }
  }

  .navbar-container {
    position: relative;
    z-index: 1000 !important;
  }

  .profileflyout-container {
    position: relative;
    z-index: 998;

    .profile-btn {
      width: 24px;
      height: 24px;
    }

    .underline-text {
      position: absolute;
      height: 2px;
      background-color: var(--bs-mch-black);
      left: -180px;
      z-index: 1000;
      margin: 0 16px;
      top: 74px;

      @media (min-width: 0) and (max-width: 767px) {
        top: 42px;
      }

      @media (min-width: 768px) and (max-width: 1279px) {
        top: 64px;
      }
    }
  }

  .profileflyout-menu {
    position: absolute;
    background-color: var(--bs-mch-white);
    min-width: 212px;
    left: -175px;
    list-style-type: none;
    padding: 0;
    margin: 0 32px 0 0;
    border-top: 2px solid var(--bs-mch-gray-200);
    top: 74px;

    @media (min-width: 0) and (max-width: 767px) {
      top: 43px;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
      top: 64px;
    }

    .item-menu-label {
      cursor: not-allowed;
      border-top: 1px solid var(--bs-mch-gray-200);
      font-weight: 700;
      color: var(--bs-mch-black);

      &.logout-label {
        cursor: pointer;
        font-weight: 400;
      }
    }

    li {
      font-size: 14px;
      line-height: 18px;
    }
  }
`

export default Header
