import http, { api } from './_http'
import url from '@constants/endpoints'

const Cms = {
  getPageByUrl: (url) =>
    http.get('/msvc/v1/pageviews', {
      url
    }),
  createNewDraft: (id): Promise<any> => api.post(`/msvc/v1/cmspages/items/${id}/create`),
  duplicateDraft: (id): Promise<any> => api.post(`/msvc/v1/cmspages/items/${id}/duplicate`),
  deletePage: (id): Promise<any> => api.delete(`/msvc/v1/cmsmenus/items/${id}`),
  deleteDraft: (id): Promise<any> => api.delete(`/msvc/v1/cmspages/items/${id}`),
  duplicatePage: (id, payload): Promise<any> => api.post(`/msvc/v1/cmsmenus/items/${id}`, payload),
  createSection: (payload): Promise<any> => api.post('/msvc/v1/cmsmenus/items/', payload),
  swapPages: (itemId, otherItemId): Promise<any> => api.post(`/msvc/v1/cmsmenus/items/${itemId}/swap/${otherItemId}`),
  updatePage: (id, params): Promise<any> => api.put(`/msvc/v1/cmspages/items/${id}`, params),
  getAllUserGroups: (): Promise<any> => api.get(`${url.userGroups}`),
  publishPage: (id): Promise<any> => api.put(`/msvc/v1/cmspages/items/${id}/publish`),
  unpublishPage: (id): Promise<any> => api.put(`/msvc/v1/cmspages/items/${id}/unpublish`),
  addModule: (id, payload): Promise<any> => api.put(`/msvc/v1/cmspages/items/modules/${id}`, payload),
  addModuleTranslation: (id, payload): Promise<any> =>
    api.post(`/msvc/v1/cmspages/items/modules/${id}/locale`, payload),
  renameModuleName: (id, payload): Promise<any> => api.put(`/msvc/v1/cmspages/items/modules/${id}/rename`, payload),
  deleteModule: (id, pageId): Promise<any> => api.delete(`/msvc/v1/cmspages/items/modules/${id}/pages/${pageId}`),
  toggleModuleVisibility: (id, pageId, visibilityValue): Promise<any> =>
    api.put(`/msvc/v1/cmspages/items/modules/${id}/pages/${pageId}/visibility`, visibilityValue)
}

export default Cms
