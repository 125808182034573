// @ts-nocheck
import apisauce from 'apisauce'
import axiosRetry from 'axios-retry'

import { clearUserTokens, getAccessToken } from '../utils/storage'
import { track } from '../utils/logger'
import { removePasswordsFromResponse } from '@utils/removePasswordsFromResponse'

const isAuthTokenInvalid = response =>
  response.status === 401 && response.data && response.data.message.indexOf('Invalid access token') !== -1

const fetch = (config) => {
  const instance = apisauce.create(config)

  axiosRetry(instance.axiosInstance, {
    retries: 2,
    retryCondition: (error) => {
      const { response } = error

      if (isAuthTokenInvalid(response)) {
        // eslint-disable-next-line no-param-reassign
        delete error.config.headers.Authorization // delete auth header for the next retry
        clearUserTokens() // will work for only browser requests
        return true
      }

      // dont retry any other request besides the one above, its not a good idea repeat failed request.
      // the only purpose of this function is retrying the requests with invalid auth headers.
      return false
    }
  })

  instance.addRequestTransform((request) => {
    const xsrfToken = getAccessToken()
    if (xsrfToken && xsrfToken.length > 0) {
      request.headers.Authorization = `Bearer ${xsrfToken}` // eslint-disable-line no-param-reassign
    }
  })

  instance.addResponseTransform((res) => {
    const response = { ...res }
    // Log only failed requests
    if (response.ok) {
      return
    }

    /**
     * Log failed network requests to TrackJS
     */
    try {
      // Its too long and makes the console verbose.
      // The rest of the response object provide enough information.
      // if (response.data && 'trace' in response.data) {
      // console.log('response.data', response.data)
      // console.log('response.data.trace 1', response.data.trace)
      // const trace: string = JSON.stringify(response.data.trace)
      // response.data.trace = trace
      // console.log('response.data.trace 2', response.data)
      // }
      const logData = {
        url: response.config?.baseURL,
        method: response.config?.method?.toUpperCase(),
        status: response.status,
        problem: response.problem,
        error: response.data.error || '',
        message: response.data.message,
        exception: response.data.exception,

        response: {
          data: response.data,
          headers: response.headers
        },
        request: {
          headers: response.config?.headers
        }
      }

      const responseWithoutPassword = removePasswordsFromResponse(response, logData)
      // Log the relevant info to the Telemetry Console
      track(`${logData.status} ${logData.error}: ${logData.method} ${logData.url}`, responseWithoutPassword)
    } catch (e) { }
  })

  // send back the sauce
  return instance
}

export default fetch