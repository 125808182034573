/* eslint-disable no-param-reassign, no-underscore-dangle, no-console, no-unused-vars */
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const processEnv = publicRuntimeConfig || process.env || {}
const { mch, ...restVars } = processEnv

const injectedVars = {
  ...(mch || { fe: { cloudinary: {}, cdn: {} } }),
  ...restVars
}

export default injectedVars || {}
