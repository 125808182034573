import http from './_http'
import url from '../constants/endpoints'

const headers = { 'cache-control': 'no-cache' }

const Recommendations = {
  getCategories: () => http.get(`${url.recommendations}/categories`, null),
  getSearch: params => http.get(`${url.recommendations}/search`, params, headers),
  getRecommenders: params => http.get(`${url.recommendations}/search/recommenders`, params, headers),
  getVenue: params => http.get(`${url.recommendations}/search/venue`, params, headers),
  getVenueMap: params => http.get(`${url.recommendations}/search/venue/map`, params, headers),
  getStatus: () => http.get(`${url.recommendations}/statuses`, null),
  updateStatus: (ids, status) => http.put(`${url.recommendations}/${ids}/${status}`, null),
  get: id => http.get(`${url.recommendations}/${id}`, null),
  set: (id, params) => http.put(`${url.recommendations}/${id}`, params),
  delete: id => http.del(`${url.recommendations}/${id}`, null),
  create: params => http.post(`${url.recommendations}`, params),
  deleteStatus: ({ ids, status }) => http.delete(`${url.recommendations}/statuses/${ids}/${status}`, null)
}

export default Recommendations
