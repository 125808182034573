import React from 'react'
import propTypes from 'prop-types'
import { Grid, Container } from 'semantic-ui-react'
import { Icon } from '@mch-group/uikit-components'

import { track } from '../utils/logger'

class ErrorBoundary extends React.Component {
  state = { error: null }

  componentDidCatch(error, info) {
    if (info && info.componentStack) {
      // The component stack is sometimes useful in development mode
      track(info.componentStack, false)
    }

    track(error)
    this.setState({ error })
  }

  render() {
    if (this.state.error) {
      return (
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column style={{ width: '130px' }}><Icon name='warning' size={50} /></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <h2>Something went wrong! Please excuse the inconvenience</h2>
            </Grid.Row>
            <Grid.Row>
              <h3>We are working on getting this fixed immediately. Please try again later.</h3>
            </Grid.Row>
          </Grid>
        </Container>
      )
    }
    return this.props.children
  }
}
//@ts-ignore
ErrorBoundary.propTypes = {
  children: propTypes.node
}

export default ErrorBoundary
