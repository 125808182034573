import App from 'next/app'
import { compose, setDisplayName } from 'recompose'
import { SWRConfig } from 'swr'
import swrContextConfig from '@services/swr/context.config'
import '@mch-group/uikit-themes/dist/artbasel/design-tokens/css/design_tokens.css'
import '@mch-group/uikit-themes/dist/artbasel/artbasel.css'
import { track } from '../utils/logger'
import Layout from '@components/Layout'

/* all SUI CSS moved to this file, and will be manually removed (e.g. headings styles) */
import '../public/static/styles/semantic.min.css'
import '../public/static/tinymce/js/tinymce/skins/ui/oxide/skin.min.css'
import '../public/static/styles/global.css'
import 'nprogress/nprogress.css'
/* uikit styles */
import '@mch-group/uikit-themes/dist/artbasel/artbasel.css'
/* design tokens */
import '@mch-group/uikit-themes/dist/artbasel/design-tokens/css/design_tokens.css'

class CMSApp extends App {
  componentDidCatch(error, errorInfo) {
    track(error)
    this.setState({ error, errorInfo })
  }

  render() {
    const { Component, pageProps } = this.props
    return (
      <SWRConfig value={swrContextConfig}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </SWRConfig>
    )
  }
}

const enhance = compose(setDisplayName('CMSApp'))

export default enhance(CMSApp)
