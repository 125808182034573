import { useRouter } from 'next/router'
import { useEffect } from 'react'
import NProgress from 'nprogress'

const useLayout = ({ children }) => {
  const router = useRouter()
  const { pathname } = children.props
  const isSignInPage = pathname === '/' || pathname === '/signin' || router.pathname.includes('artworks/uploader')

  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start())
    router.events.on('routeChangeComplete', () => NProgress.done())
    router.events.on('routeChangeError', () => NProgress.done())
  })

  return { isSignInPage, pathname, children }
}

export default useLayout