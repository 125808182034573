const cloudinary = {
  URL: `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch`,
  CARD_V1: '/w_400,q_auto:good,dpr_1.0,ar_1.6,c_crop,c_fill,g_auto/',
  UPLOAD: `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/upload`,
  throughCloudfront: (url) => {
    //@ts-ignore
    if (url && getEnvConfig?.fe?.cdn?.useCloudinaryBinariesProxy) {
      //@ts-ignore
      return url.replace('//res.cloudinary.com/', `//${getEnvConfig.fe.cdn.cloudfrontKey}.cloudfront.net${getEnvConfig.fe.cdn.cloudinaryMappingPath}`)
    } else {
      return url
    }
  }
}

export default cloudinary
