import Head from 'next/head'

// import 'semantic-ui-css/semantic.min.css'
import 'nprogress/nprogress.css'
import { memo } from 'react'
import React from 'react'

const HeadLayout = memo(() => (
  <Head>
    <meta charSet='utf-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=0' />
    <meta name='robots' content='noindex' />
    <link rel='shortcut icon' href={`/static/favicon/${getEnvConfig?.cms?.theme}/favicon.ico`} />
    <script async type='text/javascript' src='https://widget.cloudinary.com/v2.0/global/all.js' />
  </Head>
))
HeadLayout.displayName = 'HeadLayout'

export default HeadLayout
