import fetch from '../lib/fetch'

const isBrowser = typeof window !== 'undefined'
const HOST = `http://localhost:${process.env.PORT}`

export const api = fetch({
  baseURL: isBrowser ? window.location.origin : HOST,
  timeout: 60000
})

/** DEPRECATED, use "api" directly instead. "apiMethods" is not a real apisauce instance, so it's problematic */
const apiMethods = {
  get: (url, params, headers = null) => api.get(url, params, headers),
  del: url => api.delete(url, null),
  put: (url, body) => api.put(url, body),
  post: (url, body, headers = null) => api.post(url, body, { headers })
}
export default apiMethods
