import PropTypes from 'prop-types'
import { NotificationMessage } from '@dmi-mch/components'
import React, { createContext } from 'react'
import INotificationsContext from './types/INotificationsContext'

// EXAMPLE OF USE:
// Import the Context feature and the Notifications context:
// import { useContext } from 'react'
// import { NotificationsContext } from '@context/Notifications'

// Import the Context
/*
const context = useContext(NotificationsContext)
const { showNotification } = context
*/

// Use in your JSX
/*
<button
  type='button'
  onClick={() => showNotification({
    header: 'Event Saved',
    content: 'You can continue with your tasks',
    time: 3000
  })}
>Trigger notification
</button>
*/

const { useState } = React
const NotificationsContext = createContext<INotificationsContext>({} as INotificationsContext)

const NotificationsProvider = ({ children }) => {
  const defaultParams = {
    time: 3000,
    color: 'green'
  }
  const [visible, setVisible] = useState(false)
  const [message, setMessage] = useState(null)

  const showNotification = (params) => {
    // Merges the default params with those coming from the request
    const newParams = { ...defaultParams, ...params }
    // Sets the new message
    setMessage(newParams)
    // Makes it visible
    setVisible(true)
    // Hide it in the specified time
    setTimeout(() => {
      setVisible(false)
    }, newParams.time)
  }

  return (
    <NotificationsContext.Provider value={{ showNotification }}>
      {visible && (
        <NotificationMessage
          alignTopCenter
          show
          header={message.header}
          content={message.content}
          color={message.color}
        />
      )}
      {children}
    </NotificationsContext.Provider>
  )
}

NotificationsProvider.propTypes = {
  children: PropTypes.node,
  pathname: PropTypes.string
}

export default NotificationsProvider

export { NotificationsContext }
