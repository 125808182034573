import { ApiResponse } from 'apisauce'
import http, { api } from './_http'

const User = {
  signIn: (data) => {
    const {
      username, password
    } = data
    const params = {
      username,
      password,
      generateCsrfToken: true,
      expirationPolicy: 'AFTER_LOGIN'
    }
    return http.post('/msvc/v1/login', params)
  },

  getLogin: (headers): Promise<ApiResponse<ABTypes.Login.SessionSummary>> => api.get('/msvc/v1/login', null, headers),

  signOut: () => http.del('/msvc/v1/login'),

  findUser: crmGuid => api.get(`/msvc/v1/userprofiles/find?crmGuid=${crmGuid}`),

  findBasicUser: crmGuid => api.get(`/msvc/v1/userprofiles/find/basic?crmGuid=${crmGuid}`),

  getMe: (): Promise<ApiResponse<ABTypes.Userprofiles.User>> => api.get('/msvc/v1/userprofiles/me'),

  resolveUserId: (entityType, entityId) => api
    .get(`/msvc/v1/userprofiles/resolution/resolve/${entityType}/${entityId}`),

  autocomplete: (entityType, params) => http
    .get(`/msvc/v1/userprofiles/resolution/autocomplete/${entityType}`, params),

  resolveAllAccounts: (entityId, params) => http
    .get(`/msvc/v1/accounts/resolution/resolve/Account/${entityId}`, params),

  reFreshToken: token => http.post('/msvc/v1/login/refreshSession', null, {
    'X-REFRESH-AUTHORIZATION': token
  })
}

export default User
