import PropTypes from 'prop-types'

import { validateAxiosResponse, logger } from '../utils'
import { Recommendations as RecommendationsStatus } from '../services'

const { useState, useEffect } = React
const RecommendationsContext = React.createContext()

const RecommendationsProvider = ({ children, pathname }) => {
  const [statuses, setStatuses] = useState('')
  const [categories, setCategories] = useState('')
  let targetedRoutes = false

  if (pathname) {
    const url = pathname.split('/')
    let croppedPathname = `/${url[1]}`
    croppedPathname += url[2] ? `/${url[2]}` : ''
    targetedRoutes = ['/recommendations', '/recommendations/add', '/recommendations/edit'].indexOf(croppedPathname) >= 0
  }

  const fetchStatus = async (didUnmount) => {
    try {
      const result = await RecommendationsStatus.getStatus()
      if (!didUnmount && validateAxiosResponse(result)) {
        setStatuses(result.data)
      }
    } catch (error) {
      if (!didUnmount) {
        logger(error)
      }
    }
  }

  const fetchCategories = async (didUnmount) => {
    try {
      const result = await RecommendationsStatus.getCategories()
      if (!didUnmount && validateAxiosResponse(result)) {
        setCategories(result.data)
      }
    } catch (error) {
      if (!didUnmount) {
        logger(error)
      }
    }
  }

  useEffect(() => {
    let didUnmount = false
    if (targetedRoutes && !statuses && !categories) {
      fetchStatus(didUnmount)
      fetchCategories(didUnmount)
    }
    return () => {
      didUnmount = true
    }
  }, [categories, fetchCategories, fetchStatus, statuses, targetedRoutes])

  return (
    targetedRoutes
      ? (
        <RecommendationsContext.Provider value={{ statuses, categories }}>
          {children}
        </RecommendationsContext.Provider>
      )
      : children
  )
}

RecommendationsProvider.propTypes = {
  children: PropTypes.node,
  pathname: PropTypes.string
}

export default RecommendationsProvider

export { RecommendationsContext }
