import PropTypes from 'prop-types'

import { ThemeProvider } from 'styled-components'
import { StylesProvider } from '@material-ui/styles' /* to review why this is here?? */

import theme from 'dmi-ui/vars'
import UserProvider from '@context/User'
import RecommendationsProvider from '@context/Recommendations'
import NotificationsProvider from '@context/Notifications'
import ErrorBoundary from '../ErrorBoundary'
import ConfirmProvider from '@context/Confirm'
import SnackBarNotificationProvider from '@context/SnackBarNotification'
import SpinnerProvider from '@context/SpinnerProvider'
import PageProvider from '@context/Page/provider'
import GenericPopupConfirmProvider from '@context/GenericConfirmPopUp'
import Head from './Head'
import Header from './Header'
import React from 'react'
import { withRouter } from 'next/router'
import useLayout from './hooks/useLayout'

const Layout = (props) => {
  // All logic in Hook, no more logic here.
  const { isSignInPage, pathname, children } = useLayout(props)

  return (
    <main>
      <PageProvider>
        <StylesProvider disableGeneration>
          <ThemeProvider theme={theme}>
            <>
              <Head />
              <GenericPopupConfirmProvider>
                <SpinnerProvider>
                  <SnackBarNotificationProvider>
                    <NotificationsProvider>
                      <ConfirmProvider>
                        {!isSignInPage && (
                          <UserProvider>
                            <Header />
                          </UserProvider>
                        )}
                        <RecommendationsProvider pathname={pathname}>
                          <ErrorBoundary key='cms'>{children}</ErrorBoundary>
                        </RecommendationsProvider>
                      </ConfirmProvider>
                    </NotificationsProvider>
                  </SnackBarNotificationProvider>
                </SpinnerProvider>
              </GenericPopupConfirmProvider>
            </>
          </ThemeProvider>
        </StylesProvider>
      </PageProvider>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default withRouter(Layout)
