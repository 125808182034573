import url from '@constants/endpoints'
import http, { api } from './_http'
import { ApiResponse } from 'apisauce'

const headers = { 'cache-control': 'no-cache' }

const Events = {
  getSearch: (params): Promise<ApiResponse<ABTypes.Crmevent.CrmEvent>> => api.get(`${url.events}/events`, params, { headers }),
  getAccountData: id => http.get(`${url.accounts}/items/${id}`, null, headers),
  accountAutocomplete: text => http.get(`${url.accounts}/resolution/autocomplete/Account?text=${text}`, null, headers),
  // @ts-ignore
  updateStatus: body => http.put(`${url.events}/events`, body, null),
  // @ts-ignore
  updateSingleStatus: (id, status) => http.put(`${url.events}/event/${id}/status`, { status }, null),
  // @ts-ignore
  updateProperties: (id, properties) => http.put(`${url.events}/item/${id}/properties`, properties, null),
  // @ts-ignore
  delete: id => http.del(`${url.events}/event/${id}`, null),
  getTypes: params => http.get(`${url.events}/attributes/`, params, headers),
  createAttributes: (type, params) => http.post(`${url.events}/attributes/${type}/`,
    params, headers),
  // @ts-ignore
  updateAttributes: (type, id, params) => http.put(`${url.events}/attributes/${type}/${id}`, params, headers),
  // @ts-ignore
  deleteAttributes: (type, id, params) => http.del(`${url.events}/attributes/${type}/${id}`, params, headers),
  getAttributesData: (attributesId, attributesTypes) => http.get(`${url.events}/attributes/
  ${attributesTypes}/${attributesId}`, null, headers),
  createDuplicateEvent: id => http.post(`${url.events}/${id}/duplicate`, {}, headers),
  // @ts-ignore
  bulkUpdateProperties: payload => http.put(`${url.events}/properties`, payload, headers)
}

export default Events
