import { ApiResponse } from 'apisauce'
import { api } from './_http'

const newsCategories = {
  getList: (params): Promise<ApiResponse<Array<ABTypes.Monolith.Locale>>> =>
    api.get(
      `/msvc/v1/contenthubs/items/categories?includedPages=${params.includedPages}&onlyLandingCategories=${params.onlyLandingCategories}`
    ),
  createCategory: params => api.post('/msvc/v1/contenthubs/items/categories', { ...params }),
  updateCategory: (id, params) => api.put(`/msvc/v1/contenthubs/items/categories/${id}`, { id, ...params }),
  deleteCategory: (id) => api.delete(`/msvc/v1/contenthubs/items/categories/${id}`)
}

export default newsCategories
