import { TrackJS } from 'trackjs'
/* eslint-disable no-console */
let initialized = false

const getApplication = () => {
  if (process.env.NODE_ENV === 'production') {
    return `cms-${getEnvConfig?.fe.trackJs?.application}`
  } else {
    return 'cms-localhost'
  }
}

/**
 * Configure and Setup TrackJS
 */
const tjsEnvOpts = {
  version: process?.env?.softwareVersion || 'n/a',
  console: {
    display: process.env.NODE_ENV !== 'production'
  },
  network: { error: false }, // network requests tracked in lib/fetch.js
  application: getApplication(),
  // hardcoding for now, should come from config
  token: 'e46fb9563cd44dbab295d5a8fbe6ca48'
}

const init = () => {
  TrackJS.install(tjsEnvOpts)
  initialized = true
  TrackJS.addMetadata('SSR', typeof window === 'undefined' ? 'SSR' : 'No SSR')
  TrackJS.addMetadata('environment', process?.env?.environment ? process?.env?.environment : 'No ENV defined')
  TrackJS.addMetadata('NODE_ENV', process?.env?.NODE_ENV ? process?.env?.NODE_ENV : 'No NODE_ENV defined')
  TrackJS.addMetadata('application', getApplication())
}

!initialized && init()

export const track = (log: string, response = {}): void => {
  try {
    TrackJS.console.debug(response)
    TrackJS.track(log)
  } catch (e) {
    console.log(log)
  }
}
