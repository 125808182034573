import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce, isMobileDevice } from '@utils'
import { PageContext } from '@context/Page'

const useHeader = () => {

	const menuRef = useRef(null)
	const { state: { isSidebarOpen }, dispatch } = PageContext()
	const [isVisible, setVisible] = useState(false)

	const onClickMenu = () => {
    dispatch({ type: 'IS_SIDEBAR_OPEN', payload: !isSidebarOpen })
  }

	useEffect(() => {
    let debouncedHandleResize
    // in iphone the resize event is triggered when swiping up/down the page so we need to ensure this resize event is only fired in desktop (for now)
    if (!isMobileDevice()) {
      debouncedHandleResize = debounce(() => {
        dispatch({ type: 'IS_SIDEBAR_OPEN', payload: false })
      }, 50)
      window.addEventListener('resize', debouncedHandleResize)
    }
    return () => {
      if (typeof debouncedHandleResize === 'function') {
        window.removeEventListener('resize', debouncedHandleResize)
      }
    }
  }, [dispatch])

  const isProfileButton = useCallback((target) => {
    const isIcon = target.nodeName === 'svg' || target.nodeName === 'path'
    const isButton = target.classList.contains('profile-btn')
    return isIcon || isButton
  },[])

	const handleFlyoutClickAction = useCallback((event) => {
    const isProfileBtn = isProfileButton(event.target)
    if (menuRef.current && !isProfileBtn) {
      setVisible(null)
    }
  },[menuRef, isProfileButton ])

  useEffect(() => {
    document.addEventListener('click', handleFlyoutClickAction)
    return () => document.removeEventListener('click', handleFlyoutClickAction)
  }, [handleFlyoutClickAction])

	useEffect(() => {
    document.addEventListener('click', handleFlyoutClickAction)
    return () => document.removeEventListener('click', handleFlyoutClickAction)
  }, [handleFlyoutClickAction])

	return {
		menuRef,
		isVisible,
		setVisible,
		isProfileButton,
		onClickMenu
	}
}

export default useHeader
