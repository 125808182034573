import { Snackbar, SnackbarContainer } from '@mch-group/uikit-components'
import { createContext, useState } from 'react'



interface ISnackbarContent {
  message?: string,
  autohide?: boolean,
  leadingIcon?: string,
  className?: string,
  position?: string,
  style?: object,
  type?: string,
  delay?: number
}

interface ISnackbarContext {
  showToastNotification: (isShow: boolean, params: ISnackbarContent) => void
}

const SnackBarNotificationContext = createContext<ISnackbarContext>({} as ISnackbarContext)


const SnackBarNotificationProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [otherContent, setOtherContent] = useState<ISnackbarContent>({})

  const showToastNotification = (isShow: boolean, params: ISnackbarContent) => {
    const paramsCopy = { ...params }
    setShow(isShow)
    setOtherContent(paramsCopy)
  }

  return (
    <SnackBarNotificationContext.Provider value={{ showToastNotification }}>
      {show &&
      <SnackbarContainer
        className={otherContent.className}
        // @ts-ignore
        position={otherContent.position || 'top-end'}
      >
        <div
          aria-live='polite'
          aria-atomic='true'
          className='position-relative'
          style={otherContent.style}
        >
          <Snackbar
            message={otherContent.message || ''}
            autohide={otherContent.autohide}
            delay={otherContent.delay}
            //@ts-ignore
            type={otherContent.type || ''}
            show={show}
            // @ts-ignore
            onClose={() => setShow(false)}
          />
        </div>
      </SnackbarContainer>
        }
      {children}
    </SnackBarNotificationContext.Provider>
  )
}

export default SnackBarNotificationProvider

export { SnackBarNotificationContext }
