export default {
  cloudinary: {
    apiKey: 682696748472561,
    name: getEnvConfig?.fe?.cloudinary?.name || 'dqzqcuqf9',
    upload_preset: getEnvConfig?.fe?.uploadPreset?.mfpFeArtworks,
    endpoint: `https://api.cloudinary.com/v1_1/${getEnvConfig?.fe?.cloudinary?.name || 'dqzqcuqf9'}/image/upload`,
    aspectRatio: 'w_400,h_400,c_fit',
    aspectRatioSmallThumbs: 'w_100,h_100,c_fit'
  },
  uploader: {
    accept: '.jpg,.jpeg,.jpe,.png',
    maxSize: 10485760,
    minLengthOnBiggerSide: 2000,
    maxWidth: 7500,
    maxHeight: 7500
  },
  eventsExhibitions: {
    eventId: null,
    accountId: null
  },
  portfolioManager: {},
  theme: {
    bkgProgressBar: '#bdbdbd',
    bkgBar: '#444749',
    bkgPlaceHolder: '#f7f7f7',
    colorCancel: '#444749',
    colorArtworkLabels: '#444749',
    colorDisabledLabels: '#bdbdbd',
    colorArtworkAddLabels: '#bdbdbd',
    colorError: '#C9012F',
    radioBarBackground: '#d0d1d1',
    radioCircleBackground: '#dedede',
    bodycolor: 'white',
    headerseparatorcolor: '#dbdbdb'
  }
}
