// default hardcoded options commonly used in CMS dropdowns

export const defaultStatus = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'LIVE', label: 'Live' },
  { value: 'ARCHIVED', label: 'Archived' }
]

export const defaultPermissions = [
  { value: 'PRIVATE_INVITATION', label: 'Private Invitation' },
  { value: 'PUBLIC', label: 'Public' },
  { value: 'VIP', label: 'VIP' }
]

export const defaultAllOption = {
  value: 'all',
  text: 'All',
  key: 'all'
}

export const defaultNotificationStatus = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'SCHEDULE', label: 'Schedule' }
]

export default {}
