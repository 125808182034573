/*
@param { sortBy } string - whats the current sort filter in your state
@param { isAscending } bool - whats the current value of your state
@param {entity } string - whats the current sort filter you want to apply
*/
export const toggleSort = (sortBy, isAscending, entity) => {
  if (sortBy === entity && typeof isAscending !== 'undefined') {
    return !isAscending ? 'desc' : 'asc'
  }
  return ''
}

export const toggleOrder = (orderBy) => {
  const orderAndDirection = orderBy.split(' ')
  if (orderBy === orderAndDirection[0]) {
    if (orderAndDirection[1] === 'DESC') {
      return 'asc'
    }
    if (orderAndDirection[1] === 'ASC') {
      return 'desc'
    }
  }
  return ''
}

// black magic to clear all checkbox after bulk status
export const clearCheckboxes = () => {
  if (typeof document !== 'undefined') {
    const checkbox = document.querySelectorAll('.checked input[type="checkbox"]')
    checkbox.forEach((item) => {
      item.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
    })
  }
}

export default {}
