const debounce = (fn, ms: number): (() => void) => {
  let timer: NodeJS.Timeout
  return (): void => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      // @ts-ignore
      fn.apply(this, arguments)
    }, ms)
  }
}

export default debounce
