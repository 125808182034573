import { buildSelectOptions } from '@utils/dropdown'
import { memoPx2Rem as px2rem, memoHiDPI as hiDPIpx } from './cssHelpers'
import storage, { setUserTokens as setUserCookies, logoutTabs, getAccessToken, getRefreshToken, sessionAccessToken } from './storage'
import cmsBreadcrumb from './cmsBreadcrumb'
import {
  obj2qs, handleClientPath, handleServerPath, cmsPageUrl, formatOnlineShowUrl, formatOnlineRoomUrl } from './urlHelpers'
import { findObjByKey, findObjByVariableKey, findLabels, getKeyByValue } from './objectHelpers'
import validateAxiosResponse from './validateAxiosResponse'
import getTranslationByName from './getTranslationByName'
import { toggleSort, toggleOrder, clearCheckboxes } from './tableHelpers'
import { formatDate } from './dateFormat'
import { splitArrStrWithComma } from './stringParser'
import { track as logger } from './logger'
import debounce from './debounce'
import isMobileDevice from './isMobileDevice'
import cloudinary from './cloudinary'
export {
  isMobileDevice,
  logger,
  debounce,
  px2rem,
  hiDPIpx,
  storage,
  setUserCookies,
  logoutTabs,
  cmsBreadcrumb,
  handleClientPath,
  handleServerPath,
  cmsPageUrl,
  formatOnlineShowUrl,
  formatOnlineRoomUrl,
  findObjByKey,
  findObjByVariableKey,
  findLabels,
  getKeyByValue,
  validateAxiosResponse,
  getTranslationByName,
  toggleSort,
  toggleOrder,
  clearCheckboxes,
  buildSelectOptions,
  formatDate,
  splitArrStrWithComma,
  getAccessToken,
  obj2qs,
  getRefreshToken,
  sessionAccessToken,
  cloudinary
}
