const replaceAllVarsInObj = (object, variable, value) => {
  const regexSafeVariable = variable.replace(/[^\w\s]/gi, '\\$&') // Adds a "\" character before all special chars.
  const variableRegex = new RegExp(regexSafeVariable, 'gi') // Creates regex to find and replace all matches.
  return JSON.parse(JSON.stringify(object).replace(variableRegex, value))
}

export const findObjByKey = (object, key, value) => {
  let objByKey = null
  Object.keys(object).forEach((item) => {
    if (object[item][key] === value) {
      objByKey = object[item]
    }
  })
  return objByKey
}

export const findObjByVariableKey = (object, key, value) => {
  let foundObj = findObjByKey(object, key, value) // First tries to find it with usual way.
  if (!foundObj) {
    Object.keys(object).forEach((k) => {
      const item = object[k]
      if (item[key].startsWith('$')) {
        foundObj = replaceAllVarsInObj(item, item[key], value)
      }
    })
  }
  return foundObj
}

export const findLabels = (labels, language) => labels.find(label => label.key.localeCode === language) || {}

export const getKeyByValue = (obj, value) => (Object.entries(obj).find(([, v]) => v === value) || [])[0]
