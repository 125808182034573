import qs from 'query-string'

export const handleClientPath = pathname => ({ url: pathname || window.location.pathname })

export const handleServerPath = req => ({ url: req && 'url' in req ? req.url : null })

export const cmsPageUrl = (slug, page = '/index', extraParams = null) => {
  let url = `${page}?slug=${slug}`
  if (extraParams) {
    url += `&${qs.stringify(extraParams)}`
  }
  return url
}

export const formatOnlineShowUrl = showId => ({
  href: { pathname: '/onlineShows/edit', query: { id: showId } },
  url: `/onlineShows/${showId}`
})

export const formatOnlineRoomUrl = (showId, roomId) => ({
  href: { pathname: '/onlineShows/rooms/edit', query: { id: roomId } },
  url: `/onlineShows/${showId}/rooms/${roomId}`
})

export const obj2qs = (params) => {
  if (params) {
    return Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')
  }
  return ''
}
