import React, { useState, createContext } from 'react'
import { Modal } from '@mch-group/uikit-components'
import { withRouter } from 'next/router'

//@ts-ignore
const GenericPopupConfirmContext = createContext()

const GenericPopupConfirmProvider = (props) => {
  const { children } = props
  const defaultParams = {
    labels: {
      message: 'Are you sure?',
      ok: 'Ok',
      cancel: 'Cancel'
    }
  }
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [size, setSize] = useState()
  const [labels, setLabels] = useState(null)
  const [htmlBody, setHtmlBody] = useState(false)

  const showGenericConfirm = (params) => {
    // Merges the default params with those coming from the request
    const newParams = { ...defaultParams, ...params }
    // Sets the new message
    setLabels(newParams.labels)
    // setActions(newParams.actions)
    // Makes it isModalVisible
    setIsModalVisible(true)
    // set the modal size
    setSize(newParams.size)

    setHtmlBody(newParams.renderComponent)
  }

  return (
    <GenericPopupConfirmContext.Provider
      value={{
        showGenericConfirm,
        setIsModalVisible,
        isModalVisible,
        size,
        htmlBody
      }}
    >
      {isModalVisible && (
        // @ts-ignore
        <Modal show size={size}>
          <Modal.Header
            title={labels.title}
            subline={labels.subText}
            onHide={() => {
              setIsModalVisible(false)
            }}
          />
          {htmlBody}
        </Modal>
      )}
      {children}
    </GenericPopupConfirmContext.Provider>
  )
}

export default withRouter(GenericPopupConfirmProvider)

export { GenericPopupConfirmContext }
