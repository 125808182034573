/**
 * Gets an array of objects, and uses a key/value to create a comma separated string
 * e.g. Openings & receptions, Performance, Messeplatz Project, Virtual Event
 * @param {array} arr
 * @param {string} key
 */
export const splitArrStrWithComma = (arr, key) => arr.map((item, i) => (
  `${item[key]}${i !== arr.length - 1 ? ', ' : ''}`
))

export default {}
