import { createContext, useContext } from 'react'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'IS_SIDEBAR_OPEN':
      return {
        ...state,
        isSidebarOpen: action.payload
      }
    default:
      return { ...state }
  }
}

export const initialState = {
  state: {
    isSidebarOpen: false
  },
  dispatch: () => null
}

type IStateContext = {
  state: {
    isSidebarOpen: boolean
  };
  dispatch: React.Dispatch<{ type: 'IS_SIDEBAR_OPEN', payload: any }>;
}

export const StateContext = createContext<IStateContext>(initialState)

export const PageContext = () => useContext(StateContext)
