/*
  The parent, child and grandchild relations are sometimes custom made because we are handling
  legacy paths with new paths where there is not always a consistent pathname between /parent/child/grandchild

  Urls that start with "/admin/..."" are for legacy links.

  You can set use dynamic URL parameters with '$' character.
  But there should be unique on the whole document.
  The breadcrumb component automatically changes those variables when you open the mentioned page.

  For the React CMS, we can use the 'href' property to linking without refresh the page.
*/
import type { UrlObject } from 'url'

export const breadcrumbData: IBreadcrumbData[] = [
  {
    key : 'onlineShows',
    label: 'Online Shows',
    url: '/onlineShows',
    legacy: false,
    subPages: [
      {
        key : '$showId',
        label: 'Edit Online Show',
        url: '/onlineShows/$showId',
        href: { pathname: '/onlineShows/edit', query: { id: '$showId' } },
        subPages: [
          {
            key : 'rooms',
            label: 'Rooms',
            url: '/onlineShows/$showId/rooms',
            href: { pathname: '/onlineShows/edit', query: { id: '$showId', tab: 1 } },
            subPages: [
              {
                key: '$roomId',
                label: 'Room Details',
                url: '/onlineShows/$showId/rooms/$roomId',
                href: { pathname: '/onlineShows/rooms/edit', query: { id: '$roomId' } }
              }
            ]
          },
          {
            key : 'reports',
            label: 'Reports',
            url: '/onlineShows/$showId/reports',
            href: { pathname: '/onlineShows/edit', query: { id: '$showId', tab: 2 } }
          }
        ]
      }
    ]
  },
  {
    key : 'labels',
    label: 'Labels & Messages',
    url: '/labels',
    legacy: false,
    subPages: [
      {
        key : 'add',
        label: 'Add Label',
        url: '/labels/add'
      },
      {
        key : 'edit',
        label: 'Edit Label',
        url: '/labels/edit'
      }
    ],
    tabMenu: [
      {
        key : 'labels',
        label: 'Labels & Messages',
        url: '/labels'
      },
      {
        key : 'megamenueditor1',
        label: 'Megamenu Editor',
        url: '/labels/megamenueditor'
      }
    ]
  },
  {
    key : 'recommendations',
    label: 'Recommendations',
    url: '/recommendations',
    legacy: false,
    subPages: [
      {
        key : 'add',
        label: 'Add Recommendation',
        url: '/recommendations/add'
      },
      {
        key : 'edit',
        label: 'Edit Recommendations',
        url: '/recommendations/edit'
      }
    ],
    tabMenu: [
      {
        key : 'recommendations',
        label: 'Recommendations',
        url: '/recommendations'
      }
    ]
  },
  {
    key : 'events',
    label: 'Events',
    url: '/events',
    legacy: true,
    subPages: [
      {
        key : 'future',
        label: 'Future Events',
        url: '/events'
      },
      {
        key : 'past',
        label: 'Past Events',
        url: '/events/past'
      },
      {
        key : 'account',
        label: 'Account Events',
        url: '/events/account'
      },
      {
        key: 'attributes',
        label: 'Attributes',
        url: '/events/attributes'
      },
      {
        key: 'attributes-detail',
        label: 'Attribute',
        url: '/events/attributes-detail'
      },
      {
        key: 'add-attributes',
        label: 'Add Attributes',
        url: '/events/attributes-detail',
        aliasParent: 'account'
      },
      {
        key: 'add-event',
        label: 'New Event',
        url: '/events/add-event'
      },
      {
        key : 'edit',
        label: 'Edit',
        url: '/events/edit',
        aliasParent: 'account' // links to subPages[0]
      },
      {
        key : 'exhibitions',
        label: 'Account Exhibitions',
        url: '/events/exhibitions'
      },
      {
        key : 'edit-exhibition',
        label: 'Edit',
        url: '/events/edit-exhibition',
        aliasParent: 'exhibitions' // links to subPages[2]
      },
      {
        key: 'add-event',
        label: 'New Event',
        url: '/events/add-event'
      },
      {
        key: 'edit-event',
        label: 'Edit',
        url: '/events/edit-event',
        aliasParent: 'new-event'
      },
      {
        key: 'add-account',
        label: 'New Event',
        url: '/events/add-account',
        aliasParent: 'account'
      },
      {
        key: 'add-exhibition',
        label: 'New Event',
        url: '/events/add-exhibition',
        aliasParent: 'exhibitions'
      }
    ],
    tabMenu: [
      {
        key : 'future-events',
        label: 'Future Events',
        url: '/events'
      },
      {
        key : 'past-events',
        label: 'Past Events',
        url: '/events/past'
      },
      {
        key : 'attributes',
        label: 'Attributes',
        url: '/events/attributes',
        // @ts-ignore --> data unconsistency. Array of Objects, or Array of strings???
        subPages: ['/events/attributes-detail']
      },
      {
        key : 'account',
        label: 'Account Events',
        url: '/events/account',
        // @ts-ignore --> data unconsistency. Array of Objects, or Array of strings???
        subPages: ['/events/edit']
      },
      {
        key : 'exhibitions',
        label: 'Account Exhibitions',
        url: '/events/exhibitions',
        // @ts-ignore --> data unconsistency. Array of Objects, or Array of strings???
        subPages: ['/events/edit-exhibition']
      }
    ]
  },
  {
    key : 'catalog',
    label: 'Catalog',
    url: '/admin/showmngr',
    legacy: false,
    subPages: [
      {
        key : 'artworks',
        label: 'Artworks',
        url: '/admin/artworkmngr',
        subPages: [
          {
            key : 'add',
            label: 'Add Artwork',
            url: '/catalog/artworks/add'
          },
          {
            key : 'edit',
            label: 'Edit Artwork',
            url: '/catalog/artworks/edit'
          }
        ]
      }
    ],
    tabMenu: []
  },
  {
    key : 'vipSlots',
    label: 'Time slots',
    url: '/vipSlots',
    legacy: false,
    subPages: []
  },
  {
    key : 'vippackages',
    label: 'VIP Packages',
    url: '/vippackages',
    legacy: false,
    subPages: [
      {
        key : 'vippackageslist',
        label: 'VIP Packages',
        url: '/vippackages'
      },
      {
        key : 'vippackagescreate',
        label: 'Create Package',
        url: '/vippackages/create'
      }
    ],
    tabMenu: [
      {
        key : 'vippackageslist',
        label: 'List of Packages',
        url: '/vippackages'
      },
      {
        key : 'vippackagescreate',
        label: 'Create ',
        url: '/vippackages/create'
      }
    ]
  },
  {
    key : 'pushNotifications',
    label: 'App Push Notification',
    url: '/pushNotifications',
    legacy: false,
    subPages: [
      {
        key : 'future',
        label: 'Future Notifications',
        url: '/pushNotifications'
      },
      {
        key : 'past',
        label: 'Past Notifications',
        url: '/pushNotifications/past'
      },
      {
        key : 'edit',
        label: 'Edit Push Notification',
        url: '/pushNotifications/edit'
      }
    ],
    tabMenu: [
      {
        key : 'future-notifications',
        label: 'Future Notifications',
        url: '/pushNotifications'
      },
      {
        key : 'past-notifications',
        label: 'Past Notifications',
        url: '/pushNotifications/past'
      }
    ]
  },
  {
    key : 'vipcards',
    label: 'Digital VIP Cards',
    url: '/admin/vipcards',
    legacy: false,
    subPages: [
      {
        key : 'redeemedinvitations',
        label: 'Redeemed Invitations',
        url: '/vipcards/redeemedinvitations'
      }
    ],
    tabMenu: [
      {
        key : 'vipcards',
        label: 'Cards',
        url: '/admin/vipcards'
      },
      {
        key : 'redeemedinvitations',
        label: 'Redeemed Invitations',
        url: '/vipcards/redeemedinvitations'
      }
    ]
  },
  {
    key : 'news',
    label: 'News',
    url: '/news/categories',
    legacy: false,
    subPages: [
      {
        key : 'categories',
        label: 'Categories',
        url: '/news/categories',
        subPages: [
          {
            key : 'add',
            label: 'Add New Category',
            url: '/news/categories/add'
          },
          {
            key : 'edit',
            label: 'Edit Category',
            url: '/news/categories/edit'
          }
        ]
      }
    ],
    tabMenu: [
      {
        key : 'news',
        label: 'News',
        url: '/admin/news'
      },
      {
        key : 'newsCategories',
        label: 'Categories',
        url: '/news/categories'
      }
    ]
  },
  {
    key : 'megamenueditor',
    label: 'Megamenu Editor',
    url: '/labels/megamenueditor',
    legacy: false,
    subPages: [
      {
        key : 'megamenueditor1',
        label: 'Megamenu Editor',
        url: '/labels/megamenueditor'
      }
    ],
    tabMenu: [
      {
        key : 'megamenueditor1',
        label: 'Megamenu Editor',
        url: '/labels/megamenueditor'
      }
    ]
  }
]

interface IBreadcrumbData {
  key?: string,
  label?: string,
  url?: string,
  legacy?: boolean,
  href?: UrlObject,
  aliasParent?: string,
  subPages?: Array<IBreadcrumbData>,
  tabMenu?: Array<IBreadcrumbData>,
}

export default breadcrumbData
