import React, { useState, createContext, ReactNode, FC } from 'react'
import { Button, Modal } from '@mch-group/uikit-components'

/**
 * HOW TO USE
 * 1. Import the context/provider, if you're gonna use in the same place context/provider:
 * import Confirm, { ConfirmContext } from '@providers/Confirm'
 *
 * 2. How to call one of its functions, example, showNotification
 * <button onClick={() => showNotification({
        labels: {
          message: 'Are you sure you want to permanently delete the collection %nameofcollection%',
          ok: 'Delete',
          cancel: 'Cancel'
        },
        onOK: () => {},
        onCancel: () => {}
      })}
      >Click me!
      </button>

  * 3. If you don't want to wrap several components just to use this functionality:
  wrap your component like this:
  const RmHeadlineWithContext = () => <Confirm><YourComponent /></Confirm>
  and then export RmHeadlineWithContext:
  export default RmHeadlineWithContext
 */

const ConfirmContext = createContext<IConfirmContext>({} as IConfirmContext)

const ConfirmProvider: FC<IConfirmProvider> = (props) => {
  const { children } = props
  const defaultParams = {
    labels: {
      message: 'Are you sure?',
      ok: 'Ok',
      cancel: 'Cancel'
    }
  }
  const [visible, setVisible] = useState(false)
  const [size, setSize] = useState()
  const [labels, setLabels] = useState(null)
  const [actions, setActions] = useState(null)

  const showConfirm = (params) => {
    // Merges the default params with those coming from the request
    const newParams = { ...defaultParams, ...params }
    // Sets the new message
    setLabels(newParams.labels)
    setActions(newParams.actions)
    // Makes it visible
    setVisible(true)
    // set the modal size
    setSize(newParams.size)
  }

  return (
    <ConfirmContext.Provider value={{ showConfirm, setVisible, visible, size }}>
      {visible && (
        // @ts-ignore
        <Modal show size={size}>
          <Modal.Header title={labels.title} subline={labels.subText} onHide={() => setVisible(false)} />
          <Modal.Body>
            <p>{labels.message}</p>
          </Modal.Body>
          <Modal.Footer>
            {typeof actions.onCancel === 'function' && <Button onClick={() => { actions.onCancel() }} variant='secondary'>{labels.cancel}</Button>}
            {typeof actions.onOK === 'function' && <Button onClick={() => { actions.onOK() }}>{labels.ok}</Button>}
          </Modal.Footer>
        </Modal>
      )
      }
      {children}
    </ConfirmContext.Provider>
  )
}

interface IConfirmContext {
  showConfirm: (params: {
    labels: {
      title?: string, subText?: string, message?: string, cancel?: string, ok?: string
    },
    actions: {
      onOK?: () => void,
      onCancel?: () => void,
    }
  }) => void
  setVisible: any,
  visible: boolean,
  size: string
}

interface IConfirmProvider {
  children: ReactNode
}

export default ConfirmProvider

export { ConfirmContext }
