// @ts-nocheck

import { logger } from '.'

// to avoid full component/page render crashes
// map method exist only in Array.prototype. This function may fail in case "arr" argument is not an array.
// In case API server is down or doesnt provide expected data, default [] should be returned and be logged quietly.
export const buildSelectOptions = (arr, keyMap, all = false) => {
  try {
    const mapOptions = arr.map((item) => {
      const itemObj = {}
      itemObj.key = item[keyMap.key]
      itemObj.value = item[keyMap.value]
      itemObj.label = item[keyMap.name]
      itemObj.disabled = item[keyMap.disabled] ? item[keyMap.disabled] : false
      itemObj.text = item[keyMap.subText] ? `${item[keyMap.text]} ${item[keyMap.subText]}` : item[keyMap.text]
      if (item[keyMap.latitude]) {
        itemObj.latitude = item[keyMap.latitude]
      }
      if (item[keyMap.longitude]) {
        itemObj.longitude = item[keyMap.longitude]
      }
      return itemObj
    })
    all && mapOptions.unshift({ value: 'all', text: 'All', key: 'all' })
    return mapOptions
  } catch (e) {
    logger(e)
    return []
  }
}

export default {}
