import url from '@constants/endpoints'
import { ApiResponse } from 'apisauce'
import { api } from './_http'

const headers = { 'cache-control': 'no-cache' }

const PushNotifications = {
  // @ts-ignore - Still problems with headers
  getPushNotificationsList: (params): Promise<ApiResponse<ABTypes.Notifications.NotificationConfiguration[]>> => api.get(`${url.pushNotifications}`, params, headers),
  // @ts-ignore - Still problems with headers
  createNewPushNotification: (params): Promise<ApiResponse<ABTypes.Notifications.NotificationConfiguration>> => api.post(`${url.pushNotifications}`, params, headers),
  // @ts-ignore - Still problems with headers
  getAllUserGroups: (): Promise<ApiResponse<any>> => api.get(url.userGroups)
}

export default PushNotifications
