/* eslint-disable no-console */
import fetcher from './axios.config'
import { clearUserTokens } from '../../utils/storage'
import { track } from '../../utils/logger'
import { removePasswordsFromResponse } from '../../utils/removePasswordsFromResponse'

/**
 * Swr context configuration
 * It mimics the current implementation in fetch.js
 * @type {{onError: swrContextConfig.onError}}
 */
const swrContextConfig = {
  fetcher,
  revalidateOnFocus: false,
  shouldRetryOnError: false,
  onError: (error) => {
    const { response } = error
    if (
      response
      && response.status === 401
      && response.data
      && response.data.message.indexOf('Invalid access token') !== -1
    ) {
      clearUserTokens()
    }

    try {
      /**
       * Track error information
       * Remove data information as it makes the response too verbose
       */
      if (response.data && 'trace' in response.data) {
        response.data.trace = '{removed}'
      }

      const logData = {
        url: response.config.url,
        method: response.config.method.toUpperCase(),
        status: response.status,
        problem: response.problem,
        error: response.data.error || '',
        message: response.data.message,
        response: {
          data: response.data,
          headers: response.headers
        },
        request: {
          headers: response.config.headers
        }
      }

      const responseWithoutPassword = removePasswordsFromResponse(response, logData)

      track(logData, false, true)

      track(`${logData.status} ${logData.error}: ${logData.method} ${logData.url}`, responseWithoutPassword)
    } catch (e) {
      process.env.NODE_ENV !== 'production' && console.log(e)
    }
  }
}

export default swrContextConfig
