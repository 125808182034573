import { api } from './_http'
import User from './user'
import Label from './label'
import Languages from './languages'
import Cms from './cms'
import Recommendations from './recommendations'
import Place from './place'
import Cities from './cities'
import Events from './events'
import Onlineshow from './onlineshow'
import Gallery from './gallery'
import VipSlots from './vipSlots'
import PushNotifications from './pushNotifications'
import newsCategories from './newsCategories'
import Database from './database'

export {
  Database,
  VipSlots,
  User,
  Label,
  Languages,
  Cms,
  Recommendations,
  Place,
  Cities,
  Events,
  Onlineshow,
  Gallery,
  api,
  PushNotifications,
  newsCategories
}
