import qs from 'query-string'

import http from './_http'

const headers = { 'cache-control': 'no-cache' }
const Onlineshow = {
  getAll: params => http.get(`/msvc/v1/onlineshows/items?
        ${qs.stringify(params, { indices: false })}`, null, { headers }),
  createRoom: (onlineShowId, params) => http.post(`/msvc/v1/onlineshows/items/${onlineShowId}/account`, params),
  downloadReport: () => http.get('/msvc/v1/onlineshows/exports/token')
}

export default Onlineshow
