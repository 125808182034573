import { ApiResponse } from 'apisauce'
import { api } from './_http'

const Database = {
  // get a list of artworks
  getArtworks: (params): Promise<ApiResponse<any>> => api.get(`/msvc/v1/artworks/items?${params}`),
  // get a specific artwork
  getArtworkById: (id): Promise<ApiResponse<any>> => api.get(`/msvc/v1/artworks/items/${id}`),
  // get a list of artists or accounts for the autocomplete
  getAutocomplete: (entity, text) => api.get(`/msvc/v1/relationships/resolution/autocomplete/${entity}?text=${text}`),
  // get a specific artist or account for the autocomplete
  getAutocompleteById: (entity, id) => api.get(`/msvc/v1/relationships/resolution/resolve/${entity}/${id}`),
  // delete an artwork
  deleteArtwork: (id) => api.delete(`/msvc/v1/artworks/items/${id}`),
  // update an artwork
  updateArtwork: (id, body) => api.put(`/msvc/v1/artworks/items/${id}`, body),
  // create an artwork
  createArtwork: (artworkData) => api.post('/msvc/v1/artworks/items', artworkData),
  // get a list of sectors
  getSectors: () =>
    api.get('/msvc/v1/artcategories/sectornames/names')
}

export default Database
