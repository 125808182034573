import React, { useReducer, useMemo, FC, PropsWithChildren } from 'react'
import { reducer, StateContext } from '.'

const PageProvider: FC<IPageProvider> = ({ children }: PropsWithChildren<IPageProvider>) => {
  const initialState = useMemo(() => ({
    isSidebarOpen: false
  }), [])
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}

interface IPageProvider {
  props?: {
    children: React.ReactNode
  }
}

export default PageProvider
