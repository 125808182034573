import qs from 'query-string'
import http from './_http'

const vipSlots = {
  getAllShows: params => http.get(`msvc/v1/shows/items?${qs.stringify(params)}`),
  getVipPackages: () => http.get('msvc/v1/products/'),
  getSlotsForShow: showId => http.get(`/msvc/v1/showslots/show/${showId}/slots`),
  // eslint-disable-next-line max-len
  modifySlots: (showSlotsModificationRequest, showId) => http.post(`/msvc/v1/showslots/show/${showId}/slots`, showSlotsModificationRequest),
  getUserNameBasedOnId: params => http.get(`/msvc/v2/userprofiles/user/basic?${qs.stringify(params)}`)
}

export default vipSlots
