import axios from 'axios'
import { sessionAccessToken } from '@utils'

/**
 * This fetcher is used inside all SWR petitions
 * It comes with the header configured in case the user is logged in
 * @param url
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetcher = (url, params, customHeaders = {}) => {
  const userToken = sessionAccessToken()
  let headers = userToken
    ? {
      Authorization: `Bearer ${userToken}`
    }
    : {}

  headers = { ...headers, ...customHeaders }
  return axios({
    url,
    params,
    headers
  }).then(response => response.data)
}

// const headers = { headers: { 'cache-control': 'no-cache' } }

export default fetcher
