import { Spinner } from '@mch-group/uikit-components'
import { createContext, useState } from 'react'
import styled from 'styled-components'

interface ISpinnerContext {
  showSpinner: () => void
  hideSpinner: () => void,
  showSecondSpinner: () => void,
  hideSecondSpinner: () => void,
  isSpinnerActive: boolean,
  isSecondSpinnerActive: boolean
}

const SpinnerContext = createContext<ISpinnerContext>({} as ISpinnerContext)


const SpinnerProvider = ({ children }) => {
  const [show, setShow] = useState(false)
  const [showSecond, setShowSecond] = useState(false)

  const showSpinner = () => {
    setShow(true)
  }
  const hideSpinner = () => {
    setShow(false)
  }
  const showSecondSpinner = () => {
    setShowSecond(true)
  }
  const hideSecondSpinner = () => {
    setShowSecond(false)
  }

  const isSpinnerActive = show
  const isSecondSpinnerActive = showSecond

  return (
    <SpinnerContext.Provider value={{
      showSpinner, hideSpinner,
      showSecondSpinner, hideSecondSpinner, isSpinnerActive, isSecondSpinnerActive
    }}>
      {(show || showSecond) &&
        <SpinnerStyled className='d-flex align-items-center justify-content-center'>
          <Spinner variant='page' theme='light' />
        </SpinnerStyled>
      }
      {children}
    </SpinnerContext.Provider>
  )
}

const SpinnerStyled = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`

export default SpinnerProvider

export { SpinnerContext }
