import find from 'lodash/find'

const findLabelObj = (filteredObject, labelsDefinition) => {
  const newObj = filteredObject
  const { entityId, fieldName } = filteredObject[0].key
  find(labelsDefinition.labels[entityId][fieldName], () => {
    newObj[0].description = labelsDefinition.labels[entityId][fieldName].description
    newObj[0].parameters = labelsDefinition.labels[entityId][fieldName].parameters
    newObj[0].type = labelsDefinition.labels[entityId][fieldName].type
  })
  return newObj
}

const getTranslationByName = (labels, language, fieldName, labelsDefinition = {}) => {
  const filteredObject = labels
    .filter(label => label.key.localeCode.toLowerCase() === language.toLowerCase() && label.key.fieldName === fieldName)
  const finalLabel = findLabelObj(filteredObject, labelsDefinition)

  return filteredObject.length > 0 ? {
    entityType: filteredObject[0].key.entityType,
    entityId: filteredObject[0].key.entityId,
    fieldName: filteredObject[0].key.fieldName,
    value: filteredObject[0].value,
    description: finalLabel[0].description,
    parameters: finalLabel[0].parameters,
    type: finalLabel[0].type
  } : null
}

export default getTranslationByName
